body {
  margin: 0;
  font-family: "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
color: #d1c8fb;
  /* padding: 2rem; */
}

.video-player {
  position: relative;
  background-color: #121212;
  width: 100%;
  height: 100%;
  /* padding-bottom:35%; */
  /* margin-top: 10px; */
}

.video-player__video {
  position: absolute;
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
}

.video-player__button {
  cursor: pointer;
  padding: 0;
  border: 0;
  position: absolute;
  background-color: #d1c8fb;
  box-shadow: 0px 0px 8px 1px #d1c8fb;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

.video-player__button-isOpen {
  left: 0;
  top: 0;
  right: unset;
  bottom: unset;
}
/* 
.none {
  display: none;
} */

.video1 {
  width: "100%"!important;
}


@media screen and (min-width: 777px) {
  .video1 {
    width: "75%"!important; 
  }
}